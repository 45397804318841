<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
    >
      <!-- begin:: Content -->
      <div class="d-flex flex-column flex-row-fluid text-center">
        <v-row>
          <v-col align="center" cols="12" md="12">
            <v-btn
              color="primary"
              class="
                btn btn-primary
                font-size-h6
                px-15
                py-4
                my-3
                mr-3
                white--text
              "
              @click="backPage"
            >
              Back
            </v-btn>
          </v-col>
        </v-row>
        <p class="display-4 font-weight-bold">Access Denied!</p>
        <p><br /><br /></p>
        <p class="display-4 font-weight-bold">
          You are not authorized to view this page.
          <br />
          <br />
        </p>
      </div>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/error/error-6.scss";
</style>

<script>
export default {
  name: "Error-6",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/error/bg6.jpg";
    },
  },
  methods: {
    backPage() {
      this.$router.go(-1);
    },
  },
};
</script>
